<template>
  <div>
    <v-container class="bg-surface-variant">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <load-button :loader="feeHeadLoader" color="primary" text @action="insetHeadBillingMonth(1)"
            >Insert Fee Head</load-button>
        </v-col>
        <v-col cols="12" sm="4">
            <load-button :loader="ledgerHeadLoader" color="warning" text @action="insetHeadBillingMonth(2)"
            >Insert Ledger Head</load-button>
        </v-col>
        <v-col cols="12" sm="4">
            <load-button :loader="billingMonthLoader" color="success" text @action="insetHeadBillingMonth(3)"
            >Insert Billing Month</load-button>
          <!-- <v-btn color="success" outlined text>Inser Billing Month</v-btn> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["schoolId"],
  data() {
    return {
        feeHeadLoader: false,
        ledgerHeadLoader: false,
        billingMonthLoader: false,
    };
  },
  mounted() {
  },
  computed: {},
  methods: {
    insetHeadBillingMonth(status){
        if(status === 1){
            this.feeHeadLoader = true;
        }else if(status === 2){
            this.ledgerHeadLoader = true;
        }else{
            this.billingMonthLoader = true;
        }
        this.$rest.post('/api/insert-head-billing-months',{
            company_id:this.schoolId,
            status:status
        }).then((res)=>{
            if(res.status === 200){
                this.$events.fire("notification", {
                message: res.data.message,
                status: "success",
              });
            }
        }).catch((err)=>{ 
            this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });

        }).finally((f)=>{
            this.feeHeadLoader= false;
            this.ledgerHeadLoader= false;
            this.billingMonthLoader= false;
        })
        setTimeout(() => {
            this.feeHeadLoader= false;
            this.ledgerHeadLoader= false;
            this.billingMonthLoader= false;
        }, 5000);
    }

    
  },
};
</script>
